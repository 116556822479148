<template>
  <div>
    <v-card width="100%" class="pa-3" v-if="edit === 0">
      <v-card-title class="primary--text">Social Network</v-card-title>
      <v-simple-table>
        <tbody>
        <tr v-for="item in userSocialNetworks" :key="item.pivot.socialnetwork_id">
          <th style="font-size: 16px">
            <v-icon v-if="item.pivot.socialnetwork_id !== 3" class="mr-2">{{ socialNetworks.find(x => x.value === item.pivot.socialnetwork_id).icon }}</v-icon>
            <img style="filter: contrast(0.2);" v-else width="20" class="mr-3" src="../../assets/images/tiktok.svg">
            {{ socialNetworks.find(x => x.value === item.pivot.socialnetwork_id).name }}
          </th>
          <td style="font-size: 16px" class="primary--text">{{ item.pivot.link }}</td>
          <td>
            <v-btn left color="primary white--text" @click="edit = 1;useSocialNetwork(item.pivot.socialnetwork_id)">
              <v-icon>mdi-pencil-outline</v-icon>
              Edit
            </v-btn>
            <v-btn class="ml-2" :disabled="socialDeleteBtnLoad" :loading="socialDeleteBtnLoad" left
                   color="primary white--text" @click="deleteSocialNetwork(item.pivot.socialnetwork_id)">
              <v-icon>mdi-delete</v-icon>
              Delete
            </v-btn>
          </td>
        </tr>
        </tbody>
      </v-simple-table>
    </v-card>
    <div v-if="add === 1">
      <v-form ref="socialNetworkForm" v-model="socialNetworkFormValid">
        <v-row class="mt-4">
          <v-col cols="7">
            <v-text-field
              v-model="newSocialNetworkLink"
              label="Link"
              outlined
              :rules="[rules.required]"
            >
            </v-text-field>
          </v-col>
          <v-col cols="5">
            <v-select
              :items="socialNetworks"
              item-text="name"
              item-value="value"
              v-model="newSocialNetwork"
              outlined
              label="Social network"
              :rules="[rules.required]"
            >
            </v-select>
          </v-col>
        </v-row>
      </v-form>
    </div>
    <v-row>
      <v-btn class="mt-3" v-if="add === 0 && edit === 0" @click="add = 1" color="primary" text>
        <v-icon left>mdi-plus-circle</v-icon>
        Add social link
      </v-btn>
    </v-row>
    <v-row v-if="add === 1">
      <v-divider></v-divider>
    </v-row>
    <v-row v-if="add === 1">
      <v-spacer></v-spacer>
      <v-btn :loading="socialBtnLoad" @click="addSocialNetwork()" class="mt-2" color="primary">Save</v-btn>
      <v-btn class="mt-2" text @click="add = 0">Cancel</v-btn>
    </v-row>
    <v-row v-if="edit === 1">
      <v-card width="100%" class="pa-5">
        <div class="d-flex justify-space-between">
          <p>Social Network:</p>
          <p>{{ editingSocialNetwork }}</p>
        </div>
        <v-form ref="editingSocialNetworkForm" v-model="editingSocialNetworkValid">
          <v-text-field v-model="editingLink"
                        outlined
                        :rules="[rules.required]">
          </v-text-field>
          <v-btn :loading="socialEditBtnLoad" color="primary primary white--text" @click="editSocialNetwork()">
            <v-icon left>mdi-pencil-outline</v-icon>
            Submit
          </v-btn>
          <v-btn :loading="socialEditBtnLoad" color="primary primary white--text" @click="edit = 0" text>Cancel</v-btn>
        </v-form>
      </v-card>
    </v-row>
  </div>
</template>

<script>
import user from '@/web_services/user'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      add: 0,
      edit: 0,
      socialNetworkFormValid: true,
      editingSocialNetworkValid: true,
      editingSocialNetwork: null,
      editedLink: null,
      editingLink: null,
      editingSocialNetworkId: null,
      newSocialNetwork: null,
      newSocialNetworkLink: null,
      userSocialNetworks: null,
      socialBtnLoad: false,
      socialDeleteBtnLoad: false,
      socialEditBtnLoad: false,
      socialNetworks: [
        {
          value: 1,
          name: 'Facebook',
          icon: 'mdi-facebook'
        },
        {
          value: 2,
          name: 'Instagram',
          icon: 'mdi-instagram'
        },
        {
          value: 3,
          name: 'Tiktok',
          icon: 'mdi-tiktok'
        },
        {
          value: 4,
          name: 'Youtube',
          icon: 'mdi-youtube'
        },
        {
          value: 5,
          name: 'Twitter',
          icon: 'mdi-twitter'
        },
        {
          value: 6,
          name: 'Snapchat',
          icon: 'mdi-snapchat'
        },
        {
          value: 7,
          name: 'Pinterest',
          icon: 'mdi-pinterest'
        }
      ],
      rules: { required: value => !!value || 'This field Required.' }
    }
  },
  methods: {
    // uses a ceratin social network to get link and id of that to display for user to edit
    useSocialNetwork (id) {
      const network = this.userSocialNetworks.find(x => x.pivot.socialnetwork_id === id)
      this.editingSocialNetwork = network.social_network_name
      this.editingLink = network.pivot.link
      this.editingSocialNetworkId = network.pivot.socialnetwork_id
      this.editedLink = network.pivot.link
    },
    // edits a certain social network
    editSocialNetwork () {
      if (this.$refs.editingSocialNetworkForm.validate()) {
        if (this.editingSocialNetworkValid) {
          if (this.editingLink !== this.editedLink) {
            this.socialEditBtnLoad = true
            const newNetwork = {
              link: this.editingLink
            }
            user.editSocialNetwork(newNetwork, this.editingSocialNetworkId).then(() => {
              user.socialNetworks().then(res => {
                this.userSocialNetworks = res.data.data
              }).catch(error => {
                this.$emit('error', error)
              })
            }).catch(error => {
              this.$emit('error', error)
            }).finally(() => {
              this.socialEditBtnLoad = false
              this.edit = 0
              this.$emit('success', 'Social network successfully updated')
            })
          } else {
            this.edit = 0
          }
        }
      }
    },
    // Adds another social network if the user is already linked to that network it doesnt add anything
    addSocialNetwork () {
      if (this.$refs.socialNetworkForm.validate()) {
        if (this.socialNetworkFormValid) {
          this.socialBtnLoad = true
          const socialNetwork = {
            socialnetwork_id: this.newSocialNetwork,
            link: this.newSocialNetworkLink
          }
          user.addSocialNetwork(socialNetwork).then(res => {
            user.socialNetworks().then(res => {
              this.userSocialNetworks = res.data.data
              this.$emit('success', 'Social Media link added')
            }).catch(error => {
              this.$emit('error', error)
            })
          }).catch(error => {
            this.$emit('error', error)
          }).finally(() => {
            this.add = 0
            this.socialBtnLoad = false
            this.newSocialNetworkLink = ''
            this.newSocialNetwork = 0
          })
        }
      }
    },
    // Detachs a user from a certain social network
    deleteSocialNetwork (id) {
      this.socialDeleteBtnLoad = true
      user.deleteSocialNetwork(id).then(() => {
        user.socialNetworks().then(res => {
          this.userSocialNetworks = res.data.data
        }).catch(error => {
          this.$emit('error', error)
        })
      }).catch(error => {
        this.$emit('error', error)
      }).finally(() => {
        this.socialDeleteBtnLoad = false
        this.$emit('success', 'Social network successfully deleted')
      })
    }
  },
  // on component creation call the social media networks this user is registered to
  created () {
    user.socialNetworks().then(res => {
      this.userSocialNetworks = res.data.data
    })
  },
  computed: {
    ...mapState(['user'])
  }
}
</script>
